/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";

// Dashboard components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDCard from "components/MDCard";

// @mui material components
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";

// Clipboard and download components
import { CopyToClipboard } from "react-copy-to-clipboard";
import DownloadLink from "react-download-link";

// @mui material icons
import FileCopyIcon from "@mui/icons-material/FileCopy";
import GetAppIcon from "@mui/icons-material/GetApp";
import CloseIcon from "@mui/icons-material/Close";

// React-Select component and styling
import Select from "react-select";
import "../../index.css";

// Functions from store
import { getBackupDetails, restoring, getBackupList } from "../../store";

// Date-fns
import { format, addMinutes } from "date-fns";

// Layouts
import LocalSharedServer from "./server/localSharedServer";

const style = {
  position: "absolute",
  top: "35%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  color: "black",
  // bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const BackupMain = ({
  getBackupDetails,
  backupCardReducer,
  restoring,
  getBackupList,
  backupList,
  backupData,
}) => {
  useEffect(() => {
    getBackupDetails();
  }, []);

  useEffect(() => {
    getBackupList();
  }, []);

  let { directory, scheduler, lastBackupStatus, lastBackup, serverName } =
    backupCardReducer.backupCard;

  const [formattedDate, setFormattedDate] = useState("");

  useEffect(() => {
    if (lastBackup) {
      const formatDate = new Date(lastBackup);
      let tempDate = format(
        addMinutes(formatDate, formatDate.getTimezoneOffset()),
        "do MMMM yyyy, hh:mm aa"
      );

      setFormattedDate(tempDate);
    }
  }, [lastBackup]);

  let schedulerObj = {
    h: "Hours",
    d: "Daily",
    w: "Weekly",
  };

  const options = [
    { label: "Local shared server", value: "localSharedServer" },
  ];

  const initialValues = {
    servername: "",
  };

  const validationSchema = Yup.object({
    servername: Yup.string().required("Select the Server!"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
  });

  const [selectedServerName, setSelectedServerName] = useState("");

  const handleServerNameChange = (selectedServerName) => {
    setSelectedServerName(selectedServerName);
  };

  const [openEditModal, setOpenEditModal] = useState(false);

  const handleOpenEditModal = () => {
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const getServerForm = (serverFormName) => {
    switch (serverFormName) {
      case "localSharedServer":
        return <LocalSharedServer serverName="localSharedServer" />;
      default:
        return "";
    }
  };

  const [openRestoreModal, setOpenRestoreModal] = useState(false);

  const handleOpenRestoreModal = () => {
    setOpenRestoreModal(true);
  };

  const handleCloseRestoreModal = () => {
    setOpenRestoreModal(false);
  };

  const backupFileList = [];

  backupList.backupList.map((project) => {
    backupFileList.push({ label: project, value: project });
  });

  const initialValuesForRestore = {
    backupfileslist: "",
  };

  const validationSchemaForRestore = Yup.object({
    backupfileslist: Yup.string().required("Select the date!"),
  });

  const onSubmitForRestore = (values) => {
    restoring(values);
  };

  const formikForRestore = useFormik({
    initialValues: initialValuesForRestore,
    onSubmit: onSubmitForRestore,
    validationSchema: validationSchemaForRestore,
  });

  const [state, setState] = useState({ value: "some\ntext", copied: false });

  const onCopy = () => {
    setState({ ...state, copied: true });
  };

  let tempData = JSON.stringify(backupData.backupData);

  const [selectedDate, setSelectedDate] = useState("");

  const handleDateChange = (selectedDate) => {
    setSelectedDate(selectedDate);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Card sx={{ width: "45%" }}>
            <SimpleBlogCard
              icon="backup"
              color="primary"
              title="Backup"
              description={
                <span>
                  <MDTypography variant="h6">
                    Backup Type : {serverName}
                  </MDTypography>
                  <MDTypography variant="h6">
                    Backup Location : {directory}
                  </MDTypography>
                  <MDTypography variant="h6">
                    Backup Scheduler : {schedulerObj[scheduler]}
                  </MDTypography>
                  <MDTypography variant="h6">
                    Last Backup At : {formattedDate}
                  </MDTypography>
                  <MDTypography variant="h6">
                    Last Backup Status :{" "}
                    {`${lastBackupStatus ? "Success" : "Failed"}`}
                  </MDTypography>
                  <MDBox mt={4} mb={1}>
                    <MDButton
                      style={{ float: "left" }}
                      variant="outlined"
                      color="error"
                      type="button"
                      onClick={() => handleOpenEditModal()}
                    >
                      edit
                    </MDButton>
                    <MDButton
                      style={{ float: "right" }}
                      variant="outlined"
                      color="error"
                      type="button"
                      onClick={() => handleOpenRestoreModal()}
                    >
                      restore
                    </MDButton>
                  </MDBox>
                </span>
              }
            />
          </Card>
        </div>

        <Modal
          open={openEditModal}
          onClose={handleCloseEditModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MDCard sx={style}>
            <MDBox ml={3} mt={-1} display="flex" alignItems="center">
              <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                Edit backup
              </MDTypography>
              <MDBox>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  onClick={handleCloseEditModal}
                >
                  <CloseIcon />
                </IconButton>
              </MDBox>
            </MDBox>
            <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

            <MDBox pt={1} pb={3} px={3}>
              <MDBox component="form" role="form">
                <MDBox mb={2}>
                  <MDTypography variant="h6">Backup Type</MDTypography>
                  <Select
                    className="select-css"
                    placeholder="Select server..."
                    name="servername"
                    value={selectedServerName}
                    options={options}
                    onChange={(selectedOption) => {
                      handleServerNameChange(selectedOption);
                      formik.handleChange("servername")(selectedOption.value);
                    }}
                  />
                  {formik.errors.servername && (
                    <TextError msg={formik.errors.servername} />
                  )}
                </MDBox>
              </MDBox>
              <div>
                {formik.values.servername
                  ? getServerForm(formik.values.servername)
                  : ""}
              </div>
            </MDBox>
          </MDCard>
        </Modal>

        <Modal
          open={openRestoreModal}
          onClose={handleCloseRestoreModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MDCard sx={style}>
            <MDBox ml={3} mt={-1} display="flex" alignItems="center">
              <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                Restore
              </MDTypography>
              <MDBox>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  onClick={handleCloseRestoreModal}
                >
                  <CloseIcon />
                </IconButton>
              </MDBox>
            </MDBox>
            <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

            <MDBox pt={1} pb={3} px={3}>
              <MDBox
                component="form"
                role="form"
                onSubmit={formikForRestore.handleSubmit}
              >
                <MDBox>
                  <MDTypography variant="h6">Backup Files Date</MDTypography>
                  <Select
                    className="select-css"
                    placeholder="Select Date..."
                    name="backupfileslist"
                    value={selectedDate}
                    options={backupFileList}
                    onChange={(selectedOption) => {
                      handleDateChange(selectedOption);
                      formikForRestore.handleChange("backupfileslist")(
                        selectedOption.value
                      );
                    }}
                  />
                  {formikForRestore.errors.backupfileslist && (
                    <TextError msg={formikForRestore.errors.backupfileslist} />
                  )}
                </MDBox>
                {formikForRestore.values.backupfileslist ? (
                  <div>
                    <br />
                    <div>
                      <MDButton
                        style={{ backgroundColor: "red", float: "left" }}
                        color="info"
                        type="submit"
                        disabled={!formikForRestore.isValid}
                      >
                        Import
                      </MDButton>
                    </div>
                    <br />
                  </div>
                ) : null}
                {tempData.length > 2 && backupData.loading ? (
                  <MDBox>
                    <MDBox mt={2} mb={2}>
                      <MDTypography variant="h6">Back-up data</MDTypography>
                      <MDInput
                        multiline
                        rows={5}
                        name="backupdata"
                        fullWidth
                        value={tempData}
                      />
                    </MDBox>
                    <MDBox>
                      <DownloadLink
                        label={<GetAppIcon color="red" fontSize="small" />}
                        filename={`${formikForRestore.values.backupfileslist}.json`}
                        exportFile={() => tempData}
                      />
                      &nbsp;&nbsp;&nbsp;
                      <CopyToClipboard onCopy={onCopy} text={tempData}>
                        <FileCopyIcon fontSize="small" color="red" />
                      </CopyToClipboard>
                    </MDBox>
                    {state.copied ? (
                      <span style={{ color: "#6c757d" }}>Copied.</span>
                    ) : null}
                  </MDBox>
                ) : (
                  ""
                )}
              </MDBox>
            </MDBox>
          </MDCard>
        </Modal>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    backupCardReducer: state.backupCardReducer,
    backupList: state.backupList,
    backupData: state.restoringData,
  };
};
const mapStateToDispatch = (dispatch) => {
  return {
    getBackupDetails: () => dispatch(getBackupDetails()),
    restoring: (backupParameters) => dispatch(restoring(backupParameters)),
    getBackupList: () => dispatch(getBackupList()),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(BackupMain);
