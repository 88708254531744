/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  FLOWS_REQUEST,
  FLOWS_SUCCESS,
  FLOWS_FAILURE,
  FLOWS_COUNT_REQUEST,
  FLOWS_COUNT_SUCCESS,
  FLOWS_COUNT_FAILURE,
  GET_NODESBYFLOW_REQUEST,
  GET_NODESBYFLOW_SUCCESS,
  GET_NODESBYFLOW_FAILURE,
} from "./flowsTypes";
import axios from "axios";
import Config from "../../config/index";
let mobillorUrl = Config.mobillorUrl;

const flowsRequest = () => {
  return {
    type: FLOWS_REQUEST,
  };
};

const flowsSuccess = (payload) => {
  return {
    type: FLOWS_SUCCESS,
    payload: payload,
  };
};

const flowsFaliure = (error) => {
  return {
    type: FLOWS_FAILURE,
    payload: error,
  };
};

const flowsCountRequest = () => {
  return {
    type: FLOWS_COUNT_REQUEST,
  };
};

const flowsCountSuccess = (payload) => {
  return {
    type: FLOWS_COUNT_SUCCESS,
    payload: payload,
  };
};

const flowsCountFaliure = (error) => {
  return {
    type: FLOWS_COUNT_FAILURE,
    payload: error,
  };
};

const getFlows = () => {
  return function (dispatch) {
    dispatch(flowsRequest());
    let url = `${mobillorUrl}/flows`;
    axios
      .get(url)
      .then((response) => {
        dispatch(flowsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(flowsFaliure(error.message));
      });
  };
};

const getFlowsCount = () => {
  return (dispatch) => {
    dispatch(flowsCountRequest());
    let url = `${mobillorUrl}/flows`;
    axios
      .get(url)
      .then(async (response) => {
        let flowsCountInfo = {
          count: response.data.length,
        };
        dispatch(flowsCountSuccess(flowsCountInfo));
      })
      .catch((error) => {
        dispatch(flowsCountFaliure(error.message));
      });
  };
};

const getNodesByFlowRequest = () => {
  return {
    type: GET_NODESBYFLOW_REQUEST,
  };
};

const getNodesByFlowSuccess = (payload) => {
  return {
    type: GET_NODESBYFLOW_SUCCESS,
    payload: payload,
  };
};

const getNodesByFlowFaliure = (error) => {
  return {
    type: GET_NODESBYFLOW_FAILURE,
    payload: error,
  };
};

const getNodesByFlow = (id) => {
  return function (dispatch) {
    dispatch(getNodesByFlowRequest());
    let url = `${mobillorUrl}/flow/${id}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getNodesByFlowSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getNodesByFlowFaliure(error.message));
      });
  };
};

export { getFlows, getFlowsCount, getNodesByFlow };
