/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

const config = {
  auth3Url: "https://auth.mobillor.net/authapi",
  // mobillorUrl: "http://inpunisv-brcts:8000",
  // auth3Url: "http://115.112.174.164:1880",
  mobillorUrl: "https://editor.mobillor.net",
  licensesUrl: "https://auth.mobillor.net/lmsapi/api/v2",
  productName: "Digital&Industry4.0Suite",
  dashboardUrl: "http://inpunisv-brcts:1881/",
  environmentType: "development",
  userManagementUrl: "http://inpunisv-brcts:3000/api/v2/users_management",
  deviceUrl: "http://inpunisv-brcts:3000",
  clientId: process.env.REACT_APP_CLIENTID,
  productId: process.env.REACT_APP_PRODUCTID,
  serviceDeskId: "2",
  supportIssuesPayload: {
    projectId: process.env.REACT_APP_PROJECTID,
  },
};

export default config;
