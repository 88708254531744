/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useState, useEffect } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// @mui material components
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// React-Select component and styling
import Select from "react-select";
import "../../../index.css";

// Functions from store
import { setelctBackupLocation, getBackupDetails } from "../../../store";

const LocalSharedServer = ({
  serverName,
  backupOtiopsReducer,
  setelctBackupLocation,
  getBackupDetails,
}) => {
  const options = [
    { label: "Hours", value: "h" },
    { label: "Daily", value: "d" },
    { label: "Weekly", value: "w" },
  ];

  const [selectedScheduler, setSelectedScheduler] = useState("");

  const handleSchedulerChange = (selectedScheduler) => {
    setSelectedScheduler(selectedScheduler);
  };

  const [isErrorEditServer, setIsErrorEditServer] = useState(false);
  const [isSuccessEditServer, setIsSuccessEditServer] = useState(false);

  useEffect(() => {
    if (isErrorEditServer) {
      setTimeout(() => {
        setIsErrorEditServer(false);
      }, 3000);
    }
  }, [isErrorEditServer]);

  useEffect(() => {
    if (isSuccessEditServer) {
      setTimeout(() => {
        setIsSuccessEditServer(false);
      }, 3000);
    }
  }, [isSuccessEditServer]);

  const initialValues = {
    serverName: serverName,
    directory: "",
    scheduler: "",
  };

  const validationSchema = Yup.object({
    directory: Yup.string().required("Enter the directory!"),
    scheduler: Yup.string().required("Select the scheduler!"),
  });

  const onSubmit = async (values, { resetForm }) => {
    let res = await setelctBackupLocation(values);

    if (res.status) {
      setIsSuccessEditServer(true);
      resetForm();
      getBackupDetails();
    }
    if (!res.status) {
      setIsErrorEditServer(true);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <MDBox>
      <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
        <MDBox mb={2}>
          <MDTypography variant="h6">Directory Location</MDTypography>
          <MDInput
            type="text"
            name="directory"
            fullWidth
            autoComplete="off"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.directory}
            error={formik.touched.directory && formik.errors.directory && true}
          />
          {formik.touched.directory && formik.errors.directory && (
            <TextError msg={formik.errors.directory} />
          )}
        </MDBox>
        <MDBox mb={2}>
          <MDTypography variant="h6">Scheduler</MDTypography>
          <Select
            className="select-css"
            placeholder="Choose one..."
            name="scheduler"
            value={selectedScheduler}
            options={options}
            onChange={(selectedOption) => {
              handleSchedulerChange(selectedOption);
              formik.handleChange("scheduler")(selectedOption.value);
            }}
          />
          {formik.errors.scheduler && (
            <TextError msg={formik.errors.scheduler} />
          )}
        </MDBox>
        <MDBox>
          <Collapse in={isErrorEditServer}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setIsErrorEditServer(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              Please check the details. Server error!
            </Alert>
          </Collapse>
          <Collapse in={isSuccessEditServer}>
            <Alert
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setIsSuccessEditServer(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              Location has been changed!
            </Alert>
          </Collapse>
        </MDBox>
        <MDBox mt={3}>
          <MDButton
            style={{ backgroundColor: "red", float: "left" }}
            color="info"
            type="submit"
            disabled={!formik.isValid}
          >
            Change Server
          </MDButton>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

const mapStateToProps = (state) => {
  return {
    backupOtiopsReducer: state.backupOtiopsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBackupDetails: () => dispatch(getBackupDetails()),
    setelctBackupLocation: (payload) =>
      dispatch(setelctBackupLocation(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocalSharedServer);
