/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  ERROR_COUNT_REQUEST,
  ERROR_COUNT_SUCCESS,
  ERROR_COUNT_FAILURE,
} from "./errorCountTypes";
import axios from "axios";
import Config from "../../../config/index";
let mobillorUrl = Config.mobillorUrl;

const errorCountRequest = () => {
  return {
    type: ERROR_COUNT_REQUEST,
  };
};

const errorCountSuccess = (payload) => {
  return {
    type: ERROR_COUNT_SUCCESS,
    payload: payload,
  };
};

const errorCountFaliure = (error) => {
  return {
    type: ERROR_COUNT_FAILURE,
    payload: error,
  };
};

const getErrorCount = () => {
  return (dispatch) => {
    dispatch(errorCountRequest());
    let url = `${mobillorUrl}/logs_count`;
    axios
      .get(url)
      .then((response) => {
        let errorData = response.data;
        let errors = {
          warning: errorData.warn,
          error: errorData.error,
        };
        dispatch(errorCountSuccess(errors));
      })
      .catch((err) => {
        dispatch(errorCountFaliure(err.message));
      });
  };
};

export { getErrorCount };
