/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import banner from "../../../assets/banner.jpg";

// Functions from store
import { login, forgotPassword } from "../../../store";

// Config file
import Config from "../../../config/index";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Cookies
import Cookies from "universal-cookie";
const cookies = new Cookies();
let dashboardUrl = Config.dashboardUrl;

function Login({ isAuth, isAuthCall, forgotPassword, forgotPasswordData }) {
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [isErrorForgotPassword, setIsErrorForgotPassword] = useState(false);
  const [isSuccessForgotPassword, setIsSuccessForgotPassword] = useState(false);

  const [type, setType] = useState("login");

  const errorStyling = {
    color: "red",
    width: "16rem",
    fontSize: "14px",
    fontStyle: "italic",
  };

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        setIsError(false);
      }, 3000);
    }
  }, [isError]);

  useEffect(() => {
    if (isErrorForgotPassword) {
      setTimeout(() => {
        setIsErrorForgotPassword(false);
      }, 3000);
    }
  }, [isErrorForgotPassword]);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Enter the E-mail!"),
    password: Yup.string().required("Enter the password!"),
  });

  const onSubmit = async (values) => {
    let data = await isAuthCall(values);

    if (!data.status) {
      setIsError(true);
    }
    if (data.status && data.data.data.lastLogin) {
      window.sessionStorage.setItem("activeSession", true);
      cookies.set("loginDetails", JSON.stringify(data.data), { path: "/" });
      cookies.Domain = dashboardUrl;
      window.location.reload();
      setTimeout(() => {
        navigate("/home");
      }, 1000);
    }
    if (data.data.status && !data.data.data.lastLogin) {
      navigate("/authentication/set-password");
    }
  };

  const initialValuesForForgotPassword = {
    email: "",
  };

  const validationSchemaForForgotPassword = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Enter the E-mail!"),
  });

  const onSubmitForForgotPassword = async (values) => {
    let res = await forgotPassword(values);

    // if (res.status) {
    //   //
    //   //
    // }
  };

  const forgotButtonHandler = () => {
    setType("forgotpassword");
  };

  const backButtonHandler = () => {
    setType("login");
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const formikForForgotPassword = useFormik({
    initialValues: initialValuesForForgotPassword,
    onSubmit: onSubmitForForgotPassword,
    validationSchema: validationSchemaForForgotPassword,
  });

  return (
    <BasicLayout image={banner}>
      <Card>
        <MDBox
          variant="gradient"
          style={{ backgroundColor: "red" }}
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={0}>
            {type === "login" ? "Sign In" : "Forgot Password"}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          {type === "forgotpassword" && (
            <MDBox
              component="form"
              role="form"
              onSubmit={formikForForgotPassword.handleSubmit}
            >
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label="Email"
                  fullWidth
                  name="email"
                  onChange={formikForForgotPassword.handleChange}
                  onBlur={formikForForgotPassword.handleBlur}
                  value={formikForForgotPassword.values.email}
                  error={
                    formikForForgotPassword.touched.email &&
                    formikForForgotPassword.errors.email &&
                    true
                  }
                />
                {formikForForgotPassword.touched.email &&
                  formikForForgotPassword.errors.email && (
                    <TextError msg={formikForForgotPassword.errors.email} />
                  )}
              </MDBox>
              <Collapse in={isErrorForgotPassword}>
                <Alert
                  style={errorStyling}
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setIsErrorForgotPassword(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Something went wrong!
                </Alert>
              </Collapse>
              <Collapse in={isSuccessForgotPassword}>
                <Alert
                  style={errorStyling}
                  severity="success"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setIsSuccessForgotPassword(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Please check your E-mail!
                </Alert>
              </Collapse>
              <MDBox mt={4} mb={1}>
                <MDButton
                  type="button"
                  variant="outlined"
                  color="error"
                  onClick={backButtonHandler}
                >
                  back
                </MDButton>
                <MDButton
                  style={{ backgroundColor: "red", float: "right" }}
                  // variant="gradient"
                  color="info"
                  type="submit"
                  disabled={!formikForForgotPassword.isValid}
                >
                  submit
                </MDButton>
              </MDBox>
            </MDBox>
          )}
          {type === "login" && (
            <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label="Email"
                  fullWidth
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={formik.touched.email && formik.errors.email && true}
                />
                {formik.touched.email && formik.errors.email && (
                  <TextError msg={formik.errors.email} />
                )}
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Password"
                  fullWidth
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  error={
                    formik.touched.password && formik.errors.password && true
                  }
                />
                {formik.touched.password && formik.errors.password && (
                  <TextError msg={formik.errors.password} />
                )}
              </MDBox>
              <Collapse in={isError}>
                <Alert
                  style={errorStyling}
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setIsError(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Invalid E-Mail/Password
                </Alert>
              </Collapse>

              <MDBox display="flex" alignItems="center" ml={-1}>
                <MDTypography
                  variant="button"
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  color="info"
                  fontWeight="medium"
                  textGradient
                  onClick={forgotButtonHandler}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Forgot Password?
                </MDTypography>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton
                  style={{ backgroundColor: "red", float: "right" }}
                  // variant="gradient"
                  color="info"
                  type="submit"
                  disabled={!formik.isValid}
                >
                  sign in
                </MDButton>
              </MDBox>
            </MDBox>
          )}
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuth: state.login,
    forgotPasswordData: state.forgotPassword,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    isAuthCall: (loginDetails) => dispatch(login(loginDetails)),
    forgotPassword: (payload) => dispatch(forgotPassword(payload)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(Login);
