/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  GETSUBSCRIPTION_HOMEPAGE_REQUEST,
  GETSUBSCRIPTION_HOMEPAGE_SUCCESS,
  GETSUBSCRIPTION_HOMEPAGE_FAILURE,
} from "./subscriptionTypes";
import axios from "axios";
import Config from "../../config/index";
let licensesUrl = Config.licensesUrl;

// // Cookies
// import Cookies from "universal-cookie";
// const cookies = new Cookies();

// let token = cookies.get("loginDetails").jwtToken;

const getSubscriptionHomepageRequest = () => {
  return {
    type: GETSUBSCRIPTION_HOMEPAGE_REQUEST,
  };
};

const getSubscriptionHomepageSuccess = (payload) => {
  return {
    type: GETSUBSCRIPTION_HOMEPAGE_SUCCESS,
    payload: payload,
  };
};

const getSubscriptionHomepageFaliure = (error) => {
  return {
    type: GETSUBSCRIPTION_HOMEPAGE_FAILURE,
    payload: error,
  };
};

const getSubscriptionHomepage = (clientId, productId, token) => {
  return function (dispatch) {
    dispatch(getSubscriptionHomepageRequest());
    let url = `${licensesUrl}/subscriptions_management/subscription/get_info_of_subscription?client_id=${clientId}&product_id=${productId}`;
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(url, config)
      .then((response) => {
        dispatch(getSubscriptionHomepageSuccess(response.data.data));
      })
      .catch((error) => {
        dispatch(getSubscriptionHomepageFaliure(error.message));
      });
  };
};

export { getSubscriptionHomepage };
