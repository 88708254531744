/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect } from "react";
import { connect } from "react-redux";

// Dashboard example components
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";

// Functions from store
import { getFlowsCount } from "../../store";

// Config file
import Config from "../../config/index";
let mobillorUrl = Config.mobillorUrl;

const FlowsHomePage = ({ flowsCount, getFlowsCount }) => {
  useEffect(() => {
    getFlowsCount();
  }, []);

  let { count } = flowsCount.flowsCount;

  return (
    <SimpleBlogCard
      icon="account_tree"
      color="info"
      title="Flows"
      description={
        <span>
          <h4>Total Flows : {count ? count : 0}</h4>
        </span>
      }
      action={{
        type: "external",
        route: mobillorUrl,
        color: "info",
        label: "Edit Flows",
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    flowsCount: state.flowsCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFlowsCount: () => dispatch(getFlowsCount()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FlowsHomePage);
