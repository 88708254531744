/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect } from "react";
import { connect } from "react-redux";

// Dashboard example components
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";

// Functions from store
import { getErrorCount } from "../../store";

const ErrorHomePage = ({ getErrorCount, errorCount }) => {
  useEffect(() => {
    getErrorCount();
  }, []);

  return (
    <SimpleBlogCard
      icon="error"
      color="error"
      title="Error Notification"
      description={
        <span>
          <h4>
            Warning :{" "}
            {errorCount.errorCount.warning ? errorCount.errorCount.warning : 0}
          </h4>
          <h4>
            Error :{" "}
            {errorCount.errorCount.error ? errorCount.errorCount.error : 0}
          </h4>
        </span>
      }
      action={{
        type: "internal",
        route: "/error-notification-setup",
        color: "info",
        label: "Error Notification Setup",
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    errorCount: state.errorCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getErrorCount: () => dispatch(getErrorCount()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorHomePage);
