/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

export const ERROR_NOTIFICATION_SETUP_REQUEST =
  "ERROR_NOTIFICATION_SETUP_REQUEST";
export const ERROR_NOTIFICATION_SETUP_SUCCESS =
  "ERROR_NOTIFICATION_SETUP_SUCCESS";
export const ERROR_NOTIFICATION_SETUP_FAILURE =
  "ERROR_NOTIFICATION_SETUP_FAILURE";
