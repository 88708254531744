/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  BACKUP_CARD_REQUEST,
  BACKUP_CARD_SUCCESS,
  BACKUP_CARD_FAILURE,
} from "./backupCardTypes";

import axios from "axios";
import Config from "../../../config/index";
let mobillorUrl = Config.mobillorUrl;

const backupCardRequest = () => {
  return {
    type: BACKUP_CARD_REQUEST,
  };
};

const backupCardSuccess = (payload) => {
  return {
    type: BACKUP_CARD_SUCCESS,
    payload: payload,
  };
};

const backupCardFaliure = (error) => {
  return {
    type: BACKUP_CARD_FAILURE,
    payload: error,
  };
};

const getBackupDetails = () => {
  return (dispatch) => {
    dispatch(backupCardRequest());
    let url = `${mobillorUrl}/backupStatus`;
    axios
      .get(url)
      .then((response) => {
        dispatch(backupCardSuccess(response.data));
      })
      .catch((err) => {
        dispatch(backupCardFaliure(err.message));
      });
  };
};

export { getBackupDetails };
