/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect } from "react";
import { connect } from "react-redux";

// Dashboard example components
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";

// Functions from store
import { supportHomePage } from "../../store";

// Config file
import Config from "../../config/index";

// Cookies
import Cookies from "universal-cookie";
const cookies = new Cookies();

const SupportHomePage = ({ supportHomePage, supportHomePageReducer }) => {
  let userData = cookies.get("loginDetails").data;
  let supportIssuesPayload = Config.supportIssuesPayload;

  let payload = {
    reporterEmailId: userData.email,
    projectId: supportIssuesPayload.projectId,
  };

  useEffect(() => {
    supportHomePage(payload);
  }, []);

  let issueDetails = supportHomePageReducer.supportHomePage.data
    ? supportHomePageReducer.supportHomePage.data
    : {};

  return (
    <SimpleBlogCard
      icon="contact_support"
      color="dark"
      title="Support Desk"
      description={
        <span>
          <h4>To-Do Tickets : {issueDetails.todo}</h4>
          <h4>In-Progress Tickets : {issueDetails.inProgress}</h4>
          <h4>Pending Tickets : {issueDetails.pending}</h4>
          <h4>Completed Tickets : {issueDetails.completed}</h4>
        </span>
      }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    supportHomePageReducer: state.supportHomePageReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    supportHomePage: (payload) => dispatch(supportHomePage(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportHomePage);
