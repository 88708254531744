/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import React, { useEffect } from "react";
import "./PasswordStrengthMeter.css";
import zxcvbn from "zxcvbn";

function PasswordStrengthMeter({ password }) {
  const createPasswordLabel = (result) => {
    switch (result.score) {
      case 0:
        return "Weak";
      case 1:
        return "Weak";
      case 2:
        return "Fair";
      case 3:
        return "Good";
      case 4:
        return "Strong";
      default:
        return "Weak";
    }
  };

  const testedResult = zxcvbn(password);



  return (
    <div className="password-strength-meter">
      <progress
        className={`password-strength-meter-progress strength-${createPasswordLabel(
          testedResult
        )}`}
        value={testedResult.score}
        max="4"
      />
      <br />
      <label className="password-strength-meter-label">
        {password && (
          <>
            <strong>Password strength:</strong>{" "}
            {createPasswordLabel(testedResult)}
          </>
        )}
      </label>
    </div>
  );
}

// class PasswordStrengthMeter extends Component {
//   createPasswordLabel = (result) => {
//     switch (result.score) {
//       case 0:
//         return "Weak";
//       case 1:
//         return "Weak";
//       case 2:
//         return "Fair";
//       case 3:
//         return "Good";
//       case 4:
//         return "Strong";
//       default:
//         return "Weak";
//     }
//   };

//   render() {
//     const { password } = this.props;
//     const testedResult = zxcvbn(password);
//     return (
//       <div className="password-strength-meter">
//         <progress
//           className={`password-strength-meter-progress strength-${this.createPasswordLabel(
//             testedResult
//           )}`}
//           value={testedResult.score}
//           max="4"
//         />
//         <br />
//         <label className="password-strength-meter-label">
//           {password && (
//             <>
//               <strong>Password strength:</strong>{" "}
//               {this.createPasswordLabel(testedResult)}
//             </>
//           )}
//         </label>
//       </div>
//     );
//   }
// }

export default PasswordStrengthMeter;
