/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// Functions from store
import { getUsers, addUser, deleteUser } from "../../store";

// React-Select component and styling
import Select from "react-select";
import "../../index.css";

// Cookies
import Cookies from "universal-cookie";
const cookies = new Cookies();

const style = {
  position: "absolute",
  top: "35%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  color: "black",
  // bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const MobillorUsers = ({ addUser, deleteUser, getUsers, users }) => {
  let clientId = cookies.get("loginDetails").data.clientId;

  useEffect(() => {
    getUsers(clientId);
  }, [clientId]);

  const [state, setState] = useState({
    columns: [
      { Header: "Name", accessor: "name", align: "left" },
      { Header: "E-Mail", accessor: "email", align: "left" },
      { Header: "Expiry Type", accessor: "expiryType", align: "left" },
      { Header: "Remaining Days", accessor: "remainingDays", align: "left" },
      { Header: "User Role", accessor: "userRole", align: "left" },
      { Header: "Action", accessor: "action", align: "center" },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  useEffect(() => {
    let tempUsers = [];
    let usersData = users.users ? users.users : [];

    usersData.map((user) => {
      const singleUser = {
        privateKey: user.email,
        name: user.name,
        email: user.email,
        expiryType: user.expiryType,
        remainingDays: user.remainingDays,
        userRole: user.role,
        action: (
          <MDButton
            variant="text"
            color="error"
            onClick={() => handleOpenDeleteUserModal(user)}
          >
            <Icon>delete</Icon>&nbsp;delete
          </MDButton>
        ),
      };
      tempUsers.push(singleUser);
    });
    setState({ ...state, rows: tempUsers });
  }, [users]);

  const expiryTypes = [
    { label: "2 Days", value: "2_days" },
    { label: "1 Week", value: "1_week" },
    { label: "1 Month", value: "1_month" },
    { label: "Never expire", value: "never_expire" },
  ];

  const [openAddUserModal, setOpenAddUserModal] = useState(false);

  const handleOpenAddUserModal = () => {
    setOpenAddUserModal(true);
  };

  const handleCloseAddUserModal = () => {
    setOpenAddUserModal(false);
  };

  const checkEmail = (email) => {
    let tempEmailforAdmin = cookies.get("loginDetails").data.email;
    let tempEmailForNewUser = email.split("@");
    tempEmailforAdmin = tempEmailforAdmin.split("@");
    tempEmailforAdmin = tempEmailforAdmin[1].split(".");
    tempEmailForNewUser = tempEmailForNewUser[1].split(".");
    if (
      tempEmailForNewUser[0] === tempEmailforAdmin[0] ||
      tempEmailForNewUser[0] === "mobillor"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const [selectedValidity, setSelectedValidity] = useState("");

  const handleValidityChange = (selectedValidity) => {
    setSelectedValidity(selectedValidity);
  };

  const [isErrorCreateUser, setIsErrorCreateUser] = useState(false);
  const [isSuccessCreateUser, setIsSuccessCreateUser] = useState(false);
  const [isErrorForEmail, setIsErrorForEmail] = useState(false);

  useEffect(() => {
    if (isErrorCreateUser) {
      setTimeout(() => {
        setIsErrorCreateUser(false);
      }, 3000);
    }
  }, [isErrorCreateUser]);

  useEffect(() => {
    if (isSuccessCreateUser) {
      setTimeout(() => {
        setIsSuccessCreateUser(false);
      }, 3000);
    }
  }, [isSuccessCreateUser]);

  useEffect(() => {
    if (isErrorForEmail) {
      setTimeout(() => {
        setIsErrorForEmail(false);
      }, 3000);
    }
  }, [isErrorForEmail]);

  const initialValues = {
    name: "",
    email: "",
    validTill: "",
    clientId: clientId,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Enter the name!"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Enter the E-Mail!"),
    validTill: Yup.string().required("Select the validity!"),
  });

  const onSubmit = async (values, { resetForm }) => {
    if (checkEmail(values.email)) {
      let res = await addUser(values);

      if (res.status) {
        setIsSuccessCreateUser(true);
        resetForm();
        getUsers(clientId);
      }
      if (!res.status) {
        setIsErrorCreateUser(true);
      }
    } else {
      setIsErrorForEmail(true);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
  const [singleUserDataForDelete, setUserDataForDelete] = useState({});

  const handleOpenDeleteUserModal = (user = {}) => {
    setUserDataForDelete(user);
    setOpenDeleteUserModal(true);
  };
  const handleCloseDeleteUserModal = () => {
    setOpenDeleteUserModal(false);
  };

  const [isErrorDeleteUser, setIsErrorDeleteUser] = useState(false);

  useEffect(() => {
    if (isErrorDeleteUser) {
      setTimeout(() => {
        setIsErrorDeleteUser(false);
      }, 3000);
    }
  }, [isErrorDeleteUser]);

  const deleteUserData = async () => {
    let id = singleUserDataForDelete.id;
    let response = await deleteUser(id);
    if (response.status) {
      await getUsers(clientId);
      handleCloseDeleteUserModal();
    } else {
      setIsErrorDeleteUser(true);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
                mb={-6}
              >
                <MDBox>
                  <MDTypography variant="h4" gutterBottom>
                    Users
                  </MDTypography>
                </MDBox>
                <MDBox color="text" px={2} title="Add User">
                  <MDButton
                    color="info"
                    variant="outlined"
                    type="button"
                    onClick={handleOpenAddUserModal}
                  >
                    Add User
                  </MDButton>
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  canSearch={true}
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25, 30],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                />
              </MDBox>
              <Modal
                open={openAddUserModal}
                onClose={handleCloseAddUserModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={style}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Add User
                    </MDTypography>
                    <MDBox>
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseAddUserModal}
                      >
                        <CloseIcon />
                      </IconButton>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />

                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formik.handleSubmit}
                    >
                      <MDBox mb={2}>
                        <MDTypography variant="h6">Name</MDTypography>
                        <MDInput
                          type="text"
                          name="name"
                          fullWidth
                          autoComplete="off"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.name}
                          error={
                            formik.touched.name && formik.errors.name && true
                          }
                        />
                        {formik.touched.name && formik.errors.name && (
                          <TextError msg={formik.errors.name} />
                        )}
                      </MDBox>
                      <MDBox mb={2}>
                        <MDTypography variant="h6">E-Mail</MDTypography>
                        <MDInput
                          type="email"
                          name="email"
                          fullWidth
                          autoComplete="off"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          error={
                            formik.touched.email && formik.errors.email && true
                          }
                        />
                        {formik.touched.email && formik.errors.email && (
                          <TextError msg={formik.errors.email} />
                        )}
                      </MDBox>
                      <MDBox mb={2}>
                        <MDTypography variant="h6">Valid Till</MDTypography>
                        <Select
                          className="select-css"
                          placeholder="Choose one..."
                          name="validTill"
                          value={selectedValidity}
                          options={expiryTypes}
                          onChange={(selectedOption) => {
                            handleValidityChange(selectedOption);
                            formik.handleChange("validTill")(
                              selectedOption.value
                            );
                          }}
                        />
                        {formik.errors.validTill && (
                          <TextError msg={formik.errors.validTill} />
                        )}
                      </MDBox>
                      <MDBox>
                        <Collapse in={isErrorCreateUser}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorCreateUser(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Please check the details. Server error!
                          </Alert>
                        </Collapse>
                        <Collapse in={isSuccessCreateUser}>
                          <Alert
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsSuccessCreateUser(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            User Created Successfully
                          </Alert>
                        </Collapse>
                        <Collapse in={isErrorForEmail}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorForEmail(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Please check your email domain!
                          </Alert>
                        </Collapse>
                      </MDBox>
                      <MDBox mt={3}>
                        <MDButton
                          style={{ backgroundColor: "red", float: "left" }}
                          color="info"
                          type="submit"
                          disabled={!formik.isValid}
                        >
                          Add User
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={openDeleteUserModal}
                onClose={handleCloseDeleteUserModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={style}>
                  <MDTypography>Are you sure you want to delete?</MDTypography>
                  <MDBox mt={2}>
                    <MDButton
                      style={{ width: "48%", marginRight: "0.5rem" }}
                      color="info"
                      variant="outlined"
                      onClick={handleCloseDeleteUserModal}
                    >
                      Cancel
                    </MDButton>
                    <MDButton
                      style={{ width: "48%", marginLeft: "0.5rem" }}
                      color="error"
                      onClick={deleteUserData}
                    >
                      Delete
                    </MDButton>
                  </MDBox>
                  <MDBox mt={2}>
                    <Collapse in={isErrorDeleteUser}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setIsErrorDeleteUser(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        Could not delete the user!
                      </Alert>
                    </Collapse>
                  </MDBox>
                </MDCard>
              </Modal>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.mobillorUsers,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getUsers: (clientId) => dispatch(getUsers(clientId)),
    addUser: (newData) => dispatch(addUser(newData)),
    deleteUser: (id) => dispatch(deleteUser(id)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(MobillorUsers);
