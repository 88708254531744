/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

export const SET_PASSWORD_REQUEST = "SET_PASSWORD_REQUEST";
export const SET_PASSWORD_SUCCESS = "SET_PASSWORD_SUCCESS";
export const SET_PASSWORD_FAILURE = "SET_PASSWORD_FAILURE";

export const SET_PASSWORD_FOR_NEW_USER_REQUEST =
  "SET_PASSWORD_FOR_NEW_USER_REQUEST";
export const SET_PASSWORD_FOR_NEW_USER_SUCCESS =
  "SET_PASSWORD_FOR_NEW_USER_SUCCESS";
export const SET_PASSWORD_FOR_NEW_USER_FAILURE =
  "SET_PASSWORD_FOR_NEW_USER_FAILURE";
