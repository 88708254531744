/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

export const GET_ISSUES_REQUEST = "GET_ISSUES_REQUEST";
export const GET_ISSUES_SUCCESS = "GET_ISSUES_SUCCESS";
export const GET_ISSUES_FAILURE = "GET_ISSUES_FAILURE";

export const CREATE_ISSUE_REQUEST = "CREATE_ISSUE_REQUEST";
export const CREATE_ISSUE_SUCCESS = "CREATE_ISSUE_SUCCESS";
export const CREATE_ISSUE_FAILURE = "CREATE_ISSUE_FAILURE";

export const SUPPORT_HOMEPAGE_REQUEST = "SUPPORT_HOMEPAGE_REQUEST";
export const SUPPORT_HOMEPAGE_SUCCESS = "SUPPORT_HOMEPAGE_SUCCESS";
export const SUPPORT_HOMEPAGE_FAILURE = "SUPPORT_HOMEPAGE_FAILURE";

export const GET_REQUESTTYPES_REQUEST = "GET_REQUESTTYPES_REQUEST";
export const GET_REQUESTTYPES_SUCCESS = "GET_REQUESTTYPES_SUCCESS";
export const GET_REQUESTTYPES_FAILURE = "GET_REQUESTTYPES_FAILURE";

export const GET_ISSUESTATUS_REQUEST = "GET_ISSUESTATUS_REQUEST";
export const GET_ISSUESTATUS_SUCCESS = "GET_ISSUESTATUS_SUCCESS";
export const GET_ISSUESTATUS_FAILURE = "GET_ISSUESTATUS_FAILURE";

export const GET_ISSUECOMMENTS_REQUEST = "GET_ISSUECOMMENTS_REQUEST";
export const GET_ISSUECOMMENTS_SUCCESS = "GET_ISSUECOMMENTS_SUCCESS";
export const GET_ISSUECOMMENTS_FAILURE = "GET_ISSUECOMMENTS_FAILURE";
