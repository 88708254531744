/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Functions from store
import { getErrors } from "../../store";

const ErrorLog = ({ getErrors, errorLogs }) => {
  useEffect(() => {
    getErrors();
  }, []);

  const [state, setState] = useState({
    columns: [
      { Header: "Flow Name", accessor: "flowName", align: "left" },
      { Header: "Node Name", accessor: "nodeName", align: "left" },
      { Header: "Node Type", accessor: "nodeType", align: "left" },
      { Header: "Error", accessor: "error", align: "left" },
      { Header: "Editor", accessor: "editor", align: "center" },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  useEffect(() => {
    setState({ ...state, rows: errorLogs.errors });
  }, [errorLogs]);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="error"
                borderRadius="lg"
                coloredShadow="error"
              >
                <MDTypography variant="h6" color="white">
                  Errors
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  canSearch={true}
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25, 30],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    errorLogs: state.errorLogReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getErrors: (projectName) => dispatch(getErrors(projectName)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorLog);
