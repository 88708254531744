/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  RESTORING_REQUEST,
  RESTORING_SUCCESS,
  RESTORING_FAILURE,
  BACKUP_LIST_RESTORING_REQUEST,
  BACKUP_LIST_RESTORING_SUCCESS,
  BACKUP_LIST_RESTORING_FAILURE,
} from "./restoringTypes";

const initialState = {
  loading: false,
  backupData: [],
  error: "",
};

const restoringReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESTORING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RESTORING_SUCCESS:
      return {
        loading: true,
        backupData: action.payload,
        error: "",
      };
    case RESTORING_FAILURE:
      return {
        loading: false,
        backupData: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialStateForBackupList = {
  loading: false,
  backupList: [],
  error: "",
};

const backupListReducer = (state = initialStateForBackupList, action) => {
  switch (action.type) {
    case BACKUP_LIST_RESTORING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case BACKUP_LIST_RESTORING_SUCCESS:
      return {
        loading: true,
        backupList: action.payload,
        error: "",
      };
    case BACKUP_LIST_RESTORING_FAILURE:
      return {
        loading: false,
        backupList: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export { restoringReducer, backupListReducer };
