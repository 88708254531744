/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

// Utils component
import TextError from "utils/TextError";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDCard from "components/MDCard";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import TimelineItem from "examples/Timeline/TimelineItem";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

// React-Select component and styling
import Select from "react-select";
import "../../index.css";

// Functions from store
import {
  getIssues,
  createIssue,
  getRequestTypes,
  getIssueStatus,
  getIssueComments,
} from "../../store";

// Date-fns
import { format, addMinutes } from "date-fns";

// Config file
import Config from "../../config/index";

// Cookies
import Cookies from "universal-cookie";
const cookies = new Cookies();

const style = {
  position: "absolute",
  top: "35%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  color: "black",
  // bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

const tableStyle = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  color: "black",
  // bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

const Support = ({
  getIssues,
  issues,
  createIssue,
  getRequestTypes,
  requestTypes,
  getIssueStatus,
  issueStatus,
  getIssueComments,
  issueComments,
}) => {
  let userData = cookies.get("loginDetails").data;
  let clientId = cookies.get("loginDetails").data.clientId;
  let serviceDeskId = Config.serviceDeskId;
  let supportIssuesPayload = Config.supportIssuesPayload;

  let payload = {
    reporterEmailId: userData.email,
    projectId: supportIssuesPayload.projectId,
  };

  useEffect(() => {
    getIssues(payload);
  }, []);

  useEffect(() => {
    getRequestTypes();
  }, []);

  const [state, setState] = useState({
    columns: [
      { Header: "Key", accessor: "key", align: "left" },
      { Header: "Issue", accessor: "issue", align: "left" },
      { Header: "Created Date", accessor: "createdDate", align: "left" },
      { Header: "Description", accessor: "description", align: "left" },
      { Header: "Comments", accessor: "comments", align: "center" },
      { Header: "Status", accessor: "status", align: "center" },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  const [keyName, setKeyName] = useState("");
  const [issueId, setIssueId] = useState("");

  useEffect(() => {
    let tempIssues = [];
    let issuesData = issues.issues.data ? issues.issues.data : [];

    issuesData.map((issue) => {
      const formatDate = new Date(issue.fields.created);
      const singleIssue = {
        privateKey: issue.id,
        key: issue.key,
        issue: issue.fields.summary,
        createdDate: format(
          addMinutes(formatDate, formatDate.getTimezoneOffset()),
          "do MMMM yyyy, hh:mm aa"
        ),
        description: issue.fields.description,
        comments: (
          <MDButton
            variant="text"
            color="info"
            onClick={() => {
              setKeyName(issue.key);
              setIssueId(issue.id);
              handleOpenIssueCommentsModal();
            }}
          >
            &nbsp;view
          </MDButton>
        ),
        status: (
          <MDButton
            variant="text"
            color="info"
            onClick={() => {
              setKeyName(issue.key);
              setIssueId(issue.id);
              handleOpenIssueStatusModal();
            }}
          >
            &nbsp;{issue.fields.status.name}
          </MDButton>
        ),
      };
      tempIssues.push(singleIssue);
    });
    setState({ ...state, rows: tempIssues });
  }, [issues]);

  const [requestTypesOptions, setRequestTypesOptions] = useState([]);

  useEffect(() => {
    let tempRequests = [];

    let data = requestTypes.requestTypes ? requestTypes.requestTypes : [];
    data.map((request) => {
      const requestObject = {
        label: request.name,
        value: request.id,
      };
      tempRequests.push(requestObject);
    });
    setRequestTypesOptions(tempRequests);
  }, [requestTypes]);

  const [selectedRequestType, setSelectedRequestType] = useState("");

  const handleRequestTypeChange = (selectedRequestType) => {
    setSelectedRequestType(selectedRequestType);
  };

  const [openCreateIssueModal, setOpenCreateIssueModal] = useState(false);

  const handleOpenCreateIssueModal = () => {
    setOpenCreateIssueModal(true);
  };

  const handleCloseCreateIssueModal = () => {
    setOpenCreateIssueModal(false);
  };

  const [isErrorCreateIssue, setIsErrorCreateIssue] = useState(false);
  const [isSuccessCreateIssue, setIsSuccessCreateIssue] = useState(false);

  useEffect(() => {
    if (isErrorCreateIssue) {
      setTimeout(() => {
        setIsErrorCreateIssue(false);
      }, 3000);
    }
  }, [isErrorCreateIssue]);

  useEffect(() => {
    if (isSuccessCreateIssue) {
      setTimeout(() => {
        setIsSuccessCreateIssue(false);
      }, 3000);
    }
  }, [isSuccessCreateIssue]);

  const initialValues = {
    serviceDeskId: serviceDeskId,
    raiseOnBehalfOf: userData.email,
    subject: "",
    issueDescription: "",
    requestTypeId: "",
  };

  const validationSchema = Yup.object({
    subject: Yup.string().required("Enter the issue!"),
    description: Yup.string().required("Enter the description!"),
    requestTypeId: Yup.string().required("Select the priority type!"),
  });

  const onSubmit = async (values, { resetForm }) => {
    let res = await createIssue(values);
    if (res.status) {
      setIsSuccessCreateIssue(true);
      getIssues(payload);
      resetForm();
    }
    if (!res.status) {
      setIsErrorCreateIssue(true);
    }
    resetForm();
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const [commentsState, setCommentsState] = useState({
    columns: [
      { Header: "Comments", accessor: "comments", align: "left" },
      { Header: "Commented by", accessor: "commentedBy", align: "left" },
    ],
    rows: [],
  });
  const { columns: pColumns, rows: pRows } = commentsState;

  const [singleIssueData, setSingleIssueData] = useState({});

  useEffect(() => {
    getIssueStatus(issueId);
  }, [issueId]);

  useEffect(() => {
    getIssueComments(issueId);
  }, [issueId]);

  useEffect(() => {
    setSingleIssueData(issueStatus.issueStatus);
  }, [issueStatus]);

  const [createdDate, setCreatedDate] = useState("");

  useEffect(() => {
    if (singleIssueData.created) {
      const formatDate = new Date(singleIssueData.created);
      let tempDate = format(
        addMinutes(formatDate, formatDate.getTimezoneOffset()),
        "do MMMM yyyy, hh:mm aa"
      );

      setCreatedDate(tempDate);
    }
  }, [singleIssueData]);

  useEffect(() => {
    let tempComments = [];
    let data = issueComments.issueComments ? issueComments.issueComments : [];

    data.map((issue) => {
      const commentObject = {
        privateKey: issue.id,
        comments: issue.body,
        commentedBy: issue.author.displayName,
      };
      tempComments.push(commentObject);
    });
    setCommentsState({ ...commentsState, rows: tempComments });
  }, [issueComments]);

  const [openIssueStatusModal, setOpenIssueStatusModal] = useState(false);

  const handleOpenIssueStatusModal = () => {
    setOpenIssueStatusModal(true);
  };
  const handleCloseIssueStatusModal = () => {
    setOpenIssueStatusModal(false);
  };

  const [openIssueCommentsModal, setOpenIssueCommentsModal] = useState(false);

  const handleOpenIssueCommentsModal = () => {
    setOpenIssueCommentsModal(true);
  };
  const handleCloseIssueCommentsModal = () => {
    setOpenIssueCommentsModal(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
                mb={-6}
              >
                <MDBox>
                  <MDTypography variant="h4" gutterBottom>
                    Support Desk
                  </MDTypography>
                </MDBox>
                <MDBox color="text" px={2} title="Add User">
                  <MDButton
                    color="info"
                    variant="outlined"
                    type="button"
                    onClick={handleOpenCreateIssueModal}
                  >
                    Create Issue
                  </MDButton>
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  canSearch={true}
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25, 30],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                />
              </MDBox>
              <Modal
                open={openCreateIssueModal}
                onClose={handleCloseCreateIssueModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={style}>
                  <MDBox ml={3} mt={-1} display="flex" alignItems="center">
                    <MDTypography variant="h4" fontWeight="medium" flexGrow={1}>
                      Create Issue
                    </MDTypography>
                    <MDBox>
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseCreateIssueModal}
                      >
                        <CloseIcon />
                      </IconButton>
                    </MDBox>
                  </MDBox>
                  <hr style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />
                  <MDBox pt={1} pb={3} px={3}>
                    <MDBox
                      component="form"
                      role="form"
                      onSubmit={formik.handleSubmit}
                    >
                      <MDBox mb={2}>
                        <MDTypography variant="h6">Request Type</MDTypography>
                        <Select
                          className="select-css"
                          placeholder="Choose one..."
                          name="requestTypeId"
                          options={requestTypesOptions}
                          value={selectedRequestType}
                          onChange={(selectedOption) => {
                            handleRequestTypeChange(selectedOption);
                            formik.handleChange("requestTypeId")(
                              selectedOption.value
                            );
                          }}
                        />
                        {formik.errors.requestTypeId && (
                          <TextError msg={formik.errors.requestTypeId} />
                        )}
                      </MDBox>
                      <MDBox mb={2}>
                        <MDTypography variant="h6">Subject</MDTypography>
                        <MDInput
                          type="text"
                          name="subject"
                          fullWidth
                          autoComplete="off"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.subject}
                          error={
                            formik.touched.subject &&
                            formik.errors.subject &&
                            true
                          }
                        />
                        {formik.touched.subject && formik.errors.subject && (
                          <TextError msg={formik.errors.subject} />
                        )}
                      </MDBox>
                      <MDBox mb={2}>
                        <MDTypography variant="h6">Description</MDTypography>
                        <MDInput
                          multiline
                          rows={5}
                          //   type="textarea"
                          name="description"
                          fullWidth
                          autoComplete="off"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.description}
                          error={
                            formik.touched.description &&
                            formik.errors.description &&
                            true
                          }
                        />
                        {formik.touched.description &&
                          formik.errors.description && (
                            <TextError msg={formik.errors.description} />
                          )}
                      </MDBox>

                      <MDBox>
                        <Collapse in={isErrorCreateIssue}>
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsErrorCreateIssue(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Please check the issue details. Server error!
                          </Alert>
                        </Collapse>

                        <Collapse in={isSuccessCreateIssue}>
                          <Alert
                            severity="success"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  setIsSuccessCreateIssue(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            Issue Created Successfully
                          </Alert>
                        </Collapse>
                      </MDBox>
                      <MDBox mt={3}>
                        <MDButton
                          style={{ backgroundColor: "red", float: "left" }}
                          color="info"
                          type="submit"
                          disabled={!formik.isValid}
                        >
                          Create Issue
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDCard>
              </Modal>
              <Modal
                open={openIssueStatusModal}
                onClose={handleCloseIssueStatusModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={style}>
                  <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={3}
                    mb={-5}
                  >
                    <MDBox>
                      <MDTypography variant="h4" gutterBottom>
                        Status - {keyName}
                      </MDTypography>
                    </MDBox>
                  </MDBox>

                  <MDBox p={2}>
                    <TimelineItem
                      color="error"
                      icon="create"
                      title="Issue created at"
                      dateTime={createdDate}
                    />
                    {singleIssueData.assignee && (
                      <TimelineItem
                        color="info"
                        icon="person  "
                        title={`Assigned to -  ${
                          singleIssueData.assignee
                            ? singleIssueData.assignee.displayName
                            : ""
                        }`}
                        dateTime={singleIssueData?.assignee?.timeZone}
                      />
                    )}
                    {singleIssueData.currentStatus && (
                      <TimelineItem
                        color="success"
                        icon="done"
                        title={`Current status - ${singleIssueData?.currentStatus?.name}`}
                        dateTime={
                          singleIssueData?.currentStatus?.statusDate?.friendly
                        }
                        lastItem
                      />
                    )}
                  </MDBox>

                  <MDBox mt={2}>
                    <MDButton
                      style={{ backgroundColor: "red", float: "left" }}
                      color="info"
                      type="button"
                      onClick={handleCloseIssueStatusModal}
                    >
                      Close
                    </MDButton>
                  </MDBox>
                </MDCard>
              </Modal>

              <Modal
                open={openIssueCommentsModal}
                onClose={handleCloseIssueCommentsModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MDCard sx={tableStyle}>
                  <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={1}
                    mb={-6}
                  >
                    <MDBox>
                      <MDTypography variant="h4" gutterBottom>
                        Comments - {keyName}
                      </MDTypography>
                    </MDBox>
                  </MDBox>

                  <MDBox pt={3}>
                    <DataTable
                      table={{ columns: pColumns, rows: pRows }}
                      isSorted={true}
                      canSearch={true}
                      entriesPerPage={{
                        defaultValue: 5,
                        entries: [5, 10, 15, 20, 25, 30],
                      }}
                      showTotalEntries={true}
                      noEndBorder
                    />
                  </MDBox>

                  <MDBox mt={2}>
                    <MDButton
                      style={{ backgroundColor: "red", float: "left" }}
                      color="info"
                      type="button"
                      onClick={handleCloseIssueCommentsModal}
                    >
                      Close
                    </MDButton>
                  </MDBox>
                </MDCard>
              </Modal>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    issues: state.issues,
    requestTypes: state.requestTypes,
    issueStatus: state.issueStatusReducer,
    issueComments: state.issueCommentsReducer,
  };
};

const mapStateToDispatch = (dispatch) => {
  return {
    getIssues: (payload) => dispatch(getIssues(payload)),
    createIssue: (payload) => dispatch(createIssue(payload)),
    getRequestTypes: () => dispatch(getRequestTypes()),
    getIssueStatus: (id) => dispatch(getIssueStatus(id)),
    getIssueComments: (id) => dispatch(getIssueComments(id)),
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(Support);
