/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

// Icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import Modal from "@mui/material/Modal";
import Collapse from "@mui/material/Collapse";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDCard from "components/MDCard";

// Functions from store
import { getFlows, getNodesByFlow } from "../../store";

// Config file
import Config from "../../config/index";
let mobillorUrl = Config.mobillorUrl;

const tableStyle = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  color: "black",
  // bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

const Flows = ({ getFlows, flows, getNodesByFlow, nodesByFlow }) => {
  useEffect(() => {
    getFlows();
  }, []);

  const [state, setState] = useState({
    columns: [
      { Header: "Flow Name", accessor: "flowName", align: "left" },
      { Header: "Total Nodes", accessor: "TotalNodes", align: "left" },
      { Header: "Editor", accessor: "editor", align: "center" },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  const [flowId, setFlowId] = useState("");

  const [nodesState, setNodesState] = useState({
    columns: [
      { Header: "Type", accessor: "type", align: "left" },
      { Header: "Name", accessor: "name", align: "left" },
      { Header: "Status", accessor: "status", align: "left" },
      { Header: "Error", accessor: "error", align: "left" },
    ],
    rows: [],
  });
  const { columns: pColumns, rows: pRows } = nodesState;

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");

  useEffect(() => {
    let tempFlows = [];
    let data = flows.flows ? flows.flows : [];

    data.map((flow) => {
      const flowObject = {
        privateKey: flow.id,
        flowName: flow.flow,
        TotalNodes: flow.nodes.length,
        editor: (
          <MDButton variant="text" color="info">
            <MuiLink href={`${mobillorUrl}/#flow/${flow.id}`}>
              &nbsp;editor
            </MuiLink>
          </MDButton>
        ),

        nestedTableButtonComponent: (
          <IconButton
            aria-label="expand row"
            size="small"
            // onClick={() => {
            //   setTitle(flow.flow);
            //   setFlowId(flow.id);
            //   setOpen(!open);
            // }}
          >
            {flowId === flow.id && open === true ? (
              <KeyboardArrowUpIcon
                onClick={() => {
                  setTitle(flow.flow);
                  setFlowId(flow.id);
                  setOpen(false);
                }}
              />
            ) : (
              <KeyboardArrowDownIcon
                onClick={() => {
                  setTitle(flow.flow);
                  setFlowId(flow.id);
                  setOpen(true);
                }}
              />
            )}
          </IconButton>
        ),
        nestedTableComponent: (
          <Collapse
            in={flowId === flow.id && open}
            timeout="auto"
            unmountOnExit
          >
            <MDBox sx={{ margin: 1 }}>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={2}
                mb={-5}
              >
                <MDBox>
                  <MDTypography variant="h5" gutterBottom>
                    {title}
                  </MDTypography>
                </MDBox>
              </MDBox>
              <DataTable
                table={{ columns: pColumns, rows: pRows }}
                isSorted={true}
                canSearch={true}
                entriesPerPage={{
                  defaultValue: 5,
                  entries: [5, 10, 15, 20, 25, 30],
                }}
                showTotalEntries={true}
                noEndBorder
              />
            </MDBox>
          </Collapse>
        ),
      };
      tempFlows.push(flowObject);
    });
    setState({ ...state, rows: tempFlows });
  }, [flows, open, nodesState]);

  useEffect(() => {
    getNodesByFlow(flowId);
  }, [flowId]);

  useEffect(() => {
    let tempNodes = [];
    let data = nodesByFlow.nodes.nodes ? nodesByFlow.nodes.nodes : [];

    data.map((node) => {
      const nodeObject = {
        privateKey: node.id,
        type: node.type,
        name: node.name,
        status: node.status,
        error: node.error,
      };
      tempNodes.push(nodeObject);
    });
    setNodesState({ ...nodesState, rows: tempNodes });
  }, [nodesByFlow]);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="error"
                borderRadius="lg"
                coloredShadow="error"
              >
                <MDTypography variant="h6" color="white">
                  Flows
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  canSearch={true}
                  entriesPerPage={false}
                  totalRecords={rows.length}
                  showTotalEntries={true}
                  noEndBorder
                  nestedTable={true}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    flows: state.flows,
    nodesByFlow: state.nodesByFlowReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFlows: () => dispatch(getFlows()),
    getNodesByFlow: (id) => dispatch(getNodesByFlow(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Flows);
