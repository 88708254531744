/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Dashboard example components
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";

// Functions from store
import { backupNow, getBackupDetails } from "../../store";

// Dashboard components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// @mui material components
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";

// @mui material icons
import CloseIcon from "@mui/icons-material/Close";

const BackupHomePage = ({ backupNow, getBackupDetails, backupCardReducer }) => {
  const [msgSuccess, setMsgSuccess] = useState(false);
  const [msgError, setMsgError] = useState(false);

  useEffect(() => {
    getBackupDetails();
  }, []);

  const forceBackupNow = async () => {
    let res = await backupNow();
    if (res.status) {
      setMsgSuccess(res.status);
    } else {
      setMsgError(true);
    }
  };

  let { directory, scheduler, lastBackupStatus, lastBackup, serverName } =
    backupCardReducer.backupCard;

  let schedulerObj = {
    h: "Hours",
    d: "Daily",
    w: "Weekly",
  };

  return (
    <SimpleBlogCard
      icon="backup"
      color="warning"
      title="Backup"
      description={
        <span>
          <h4>Backup Type : {serverName ? serverName : "Not available"}</h4>
          <h4>Backup Loaction : {directory ? directory : "Not available"}</h4>
          <h4>
            Backup Scheduler :{" "}
            {schedulerObj[scheduler]
              ? schedulerObj[scheduler]
              : "Not available"}
          </h4>
          <h4>
            Last Backup Status : {`${lastBackupStatus ? "Success" : "Failed"}`}
          </h4>
          <MDButton
            color="info"
            variant="text"
            style={{ position: "absolute", left: "0px", bottom: "10px" }}
            onClick={forceBackupNow}
          >
            backup now
          </MDButton>
          <MDBox>
            <Collapse in={msgError}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setMsgError(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Error!
              </Alert>
            </Collapse>

            <Collapse in={msgSuccess}>
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setMsgSuccess(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                Force backup done!
              </Alert>
            </Collapse>
          </MDBox>
        </span>
      }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    backupCardReducer: state.backupCardReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    backupNow: () => dispatch(backupNow()),
    getBackupDetails: () => dispatch(getBackupDetails()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BackupHomePage);
