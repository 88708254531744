/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

export const FLOWS_REQUEST = "FLOWS_REQUEST";
export const FLOWS_SUCCESS = "FLOWS_SUCCESS";
export const FLOWS_FAILURE = "FLOWS_FAILURE";

export const FLOWS_COUNT_REQUEST = "FLOWS_COUNT_REQUEST";
export const FLOWS_COUNT_SUCCESS = "FLOWS_COUNT_SUCCESS";
export const FLOWS_COUNT_FAILURE = "FLOWS_COUNT_FAILURE";

export const GET_NODESBYFLOW_REQUEST = "GET_NODESBYFLOW_REQUEST";
export const GET_NODESBYFLOW_SUCCESS = "GET_NODESBYFLOW_SUCCESS";
export const GET_NODESBYFLOW_FAILURE = "GET_NODESBYFLOW_FAILURE";
