/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  BACKUP_NOW_REQUEST,
  BACKUP_NOW_SUCCESS,
  BACKUP_NOW_FAILURE,
} from "./backupNowTypes";
import axios from "axios";
import Config from "../../../config/index";
let mobillorUrl = Config.mobillorUrl;

const backupNowRequest = () => {
  return {
    type: BACKUP_NOW_REQUEST,
  };
};

const backupNowSuccess = (payload) => {
  return {
    type: BACKUP_NOW_SUCCESS,
    payload: payload,
  };
};

const backupNowFaliure = (error) => {
  return {
    type: BACKUP_NOW_FAILURE,
    payload: error,
  };
};

const backupNow = () => {
  return (dispatch) => {
    dispatch(backupNowRequest());
    let url = `${mobillorUrl}/forceBackup`;
    return axios
      .get(url)
      .then((response) => {
        dispatch(backupNowSuccess(response.data));
        return { status: true };
      })
      .catch((err) => {
        dispatch(backupNowFaliure(err.message));
        return { status: false };
      });
  };
};

export { backupNow };
