/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import React from "react";
import {
  ERROR_LOG_REQUEST,
  ERROR_LOG_SUCCESS,
  ERROR_LOG_FAILURE,
} from "./errorLogTypes";
import axios from "axios";
import MDButton from "components/MDButton";
import MuiLink from "@mui/material/Link";
import Config from "../../../config/index";
let mobillorUrl = Config.mobillorUrl;

const errorLogRequest = () => {
  return {
    type: ERROR_LOG_REQUEST,
  };
};

const errorLogSuccess = (payload) => {
  return {
    type: ERROR_LOG_SUCCESS,
    payload: payload,
  };
};

const errorLogFaliure = (error) => {
  return {
    type: ERROR_LOG_FAILURE,
    payload: error,
  };
};

const getErrors = () => {
  return (dispatch) => {
    dispatch(errorLogRequest());
    let url = `${mobillorUrl}/logs`;
    axios
      .get(url)
      .then((response) => {
        let errors = [];
        response.data.map((error) => {
          if (error.type) {
            errors.push({
              flowName: error.flowName,
              nodeName: error.nodeName,
              nodeType: error.type,
              error:
                typeof error.msg == "object"
                  ? "function node error"
                  : error.msg,
              editor: (
                <MDButton variant="text" color="info">
                  <MuiLink href={`${mobillorUrl}/#flow/${error.z}`}>
                    &nbsp;editor
                  </MuiLink>
                </MDButton>
              ),
            });
          }
        });

        dispatch(errorLogSuccess(errors));
      })
      .catch((err) => {
        dispatch(errorLogFaliure(err.message));
      });
  };
};

export { getErrors };
