/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 * 
 *  Mobillor Technologies Pvt. Ltd. 
 *  All Rights Reserved.
 * 
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.  
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
*/

import {
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE
} from './forgotPasswordTypes';
import axios from 'axios';
import Config from '../../../config/index'
let auth3Url = Config.auth3Url
let dashboardUrl = Config.dashboardUrl

const forgotPasswordRequest = () => {
    return {
        type: FORGOT_PASSWORD_REQUEST
    }
}

const forgotPasswordSuccess = (payload) => {
    return {
        type: FORGOT_PASSWORD_SUCCESS,
        payload: payload
    }
}

const forgotPasswordFaliure = (error) => {
    return {
        type: FORGOT_PASSWORD_FAILURE,
        payload: error
    }
}

const forgotPassword = (forgotPasswordPayload) => {
    return (dispatch) => {
        dispatch(forgotPasswordRequest())
        let url = `${auth3Url}/password/forgot`
        forgotPasswordPayload["url"] = `${dashboardUrl}/set_password`
        axios.post(url, forgotPasswordPayload).then(response => {
            dispatch(forgotPasswordSuccess(response.data))
        }).catch(err => {
            dispatch(forgotPasswordFaliure(err.message))
        })
    }
}

export { forgotPassword } 
