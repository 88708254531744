/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Dashboard example components
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";

// Functions from store
import { getSubscriptionHomepage } from "../../store";

// Dashboard components
import MDProgress from "components/MDProgress";

// Date-fns
import { format, addMinutes } from "date-fns";

// Config
import Config from "../../config/index";

// Cookies
import Cookies from "universal-cookie";
const cookies = new Cookies();

const SubscriptionHomePage = ({
  getSubscriptionHomepage,
  subscriptionHomepage,
}) => {
  let userData = cookies.get("loginDetails");
  let clientId = Config.clientId;
  let productId = Config.productId;
  // let productId =
  //   cookies.get("loginDetails").data.products["Digital&Industry4.0Suite"]
  //     .productId;

  useEffect(() => {
    getSubscriptionHomepage(clientId, productId, userData.jwtToken);
  }, []);

  const [subscriptionData, setSubscriptionData] = useState({});

  const [formattedStartDate, setFormattedStartDate] = useState("");
  const [formattedEndDate, setFormattedEndDate] = useState("");

  useEffect(() => {
    setSubscriptionData(subscriptionHomepage.subscriptionHomepage);
  }, [subscriptionHomepage]);

  const [barValue, setBarValue] = useState(0);

  useEffect(() => {
    if (subscriptionData?.percentage) {
      setBarValue(100 - +subscriptionData?.percentage?.split("%")[0]);
    }
  }, [subscriptionData]);

  const [barColour, setBarColour] = useState("error");

  useEffect(() => {
    if (barValue >= 76 && barValue <= 100) {
      setBarColour("success");
    }
    if (barValue >= 51 && barValue <= 75) {
      setBarColour("info");
    }
    if (barValue >= 26 && barValue <= 50) {
      setBarColour("warning");
    }
    if (barValue >= 0 && barValue <= 25) {
      setBarColour("error");
    }
  }, [barValue]);

  useEffect(() => {
    if (subscriptionData?.startDate) {
      const formatDate = new Date(subscriptionData.startDate);
      let tempStartDate = format(
        addMinutes(formatDate, formatDate.getTimezoneOffset()),
        "do MMMM yyyy"
      );

      setFormattedStartDate(tempStartDate);
    }
    if (subscriptionData?.endDate) {
      const formatDate = new Date(subscriptionData.endDate);
      let tempEndDate = format(
        addMinutes(formatDate, formatDate.getTimezoneOffset()),
        "do MMMM yyyy"
      );

      setFormattedEndDate(tempEndDate);
    }
  }, [subscriptionData]);

  return (
    <SimpleBlogCard
      icon="card_membership"
      color="success"
      title="Subscription"
      description={
        <span>
          <MDProgress
            style={{ marginBottom: "1rem", height: "10px" }}
            value={barValue}
            color={barColour}
          />
          {/* <h4>Start Date : {subscriptionData.startDate}</h4>
          <h4>Expiry Date : {subscriptionData.endDate}</h4> */}
          <h4>Start Date : {formattedStartDate}</h4>
          <h4>Expiry Date : {formattedEndDate}</h4>
        </span>
      }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    subscriptionHomepage: state.subscriptionHomepage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubscriptionHomepage: (clientId, productId, token) =>
      dispatch(getSubscriptionHomepage(clientId, productId, token)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionHomePage);
