/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  GET_ISSUES_REQUEST,
  GET_ISSUES_SUCCESS,
  GET_ISSUES_FAILURE,
  CREATE_ISSUE_REQUEST,
  CREATE_ISSUE_SUCCESS,
  CREATE_ISSUE_FAILURE,
  SUPPORT_HOMEPAGE_REQUEST,
  SUPPORT_HOMEPAGE_SUCCESS,
  SUPPORT_HOMEPAGE_FAILURE,
  GET_REQUESTTYPES_REQUEST,
  GET_REQUESTTYPES_SUCCESS,
  GET_REQUESTTYPES_FAILURE,
  GET_ISSUESTATUS_REQUEST,
  GET_ISSUESTATUS_SUCCESS,
  GET_ISSUESTATUS_FAILURE,
  GET_ISSUECOMMENTS_REQUEST,
  GET_ISSUECOMMENTS_SUCCESS,
  GET_ISSUECOMMENTS_FAILURE,
} from "./supportTypes";
import axios from "axios";
import Config from "../../config/index";
let licensesUrl = Config.licensesUrl;
let serviceDeskId = Config.serviceDeskId;

const getIssuesRequest = () => {
  return {
    type: GET_ISSUES_REQUEST,
  };
};

const getIssuesSuccess = (payload) => {
  return {
    type: GET_ISSUES_SUCCESS,
    payload: payload,
  };
};

const getIssuesFaliure = (error) => {
  return {
    type: GET_ISSUES_FAILURE,
    payload: error,
  };
};

const createIssueRequest = () => {
  return {
    type: CREATE_ISSUE_REQUEST,
  };
};

const createIssueSuccess = (payload) => {
  return {
    type: CREATE_ISSUE_SUCCESS,
    payload: payload,
  };
};

const createIssueFaliure = (error) => {
  return {
    type: CREATE_ISSUE_FAILURE,
    payload: error,
  };
};

const getRequestTypesRequest = () => {
  return {
    type: GET_REQUESTTYPES_REQUEST,
  };
};

const getRequestTypesSuccess = (payload) => {
  return {
    type: GET_REQUESTTYPES_SUCCESS,
    payload: payload,
  };
};

const getRequestTypesFaliure = (error) => {
  return {
    type: GET_REQUESTTYPES_FAILURE,
    payload: error,
  };
};

const getIssues = (payload) => {
  return (dispatch) => {
    dispatch(getIssuesRequest());
    let url = `${licensesUrl}/jira/requests/requests_by_reporter`;
    axios
      .post(url, payload)
      .then((response) => {
        dispatch(getIssuesSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(getIssuesFaliure(err.message));
        return { status: false, message: err.message };
      });
  };
};

const createIssue = (payload) => {
  return (dispatch) => {
    dispatch(createIssueRequest());
    let url = `${licensesUrl}/jira/requests`;
    return axios
      .post(url, payload)
      .then((response) => {
        dispatch(createIssueSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(createIssueFaliure(err.message));
        return { status: false, message: err.message };
      });
  };
};

const getRequestTypes = () => {
  return (dispatch) => {
    dispatch(getRequestTypesRequest());
    let url = `${licensesUrl}/jira/requests/requesttypes?desk_id=${serviceDeskId}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getRequestTypesSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getRequestTypesFaliure(err.message));
      });
  };
};

const supportCardDashboardRequest = () => {
  return {
    type: SUPPORT_HOMEPAGE_REQUEST,
  };
};

const supportCardDashboardSuccess = (payload) => {
  return {
    type: SUPPORT_HOMEPAGE_SUCCESS,
    payload: payload,
  };
};

const supportCardDashboardFaliure = (error) => {
  return {
    type: SUPPORT_HOMEPAGE_FAILURE,
    payload: error,
  };
};

const supportHomePage = (payload) => {
  return (dispatch) => {
    dispatch(supportCardDashboardRequest());
    let url = `${licensesUrl}/jira/requests/requests_count`;
    axios
      .post(url, payload)
      .then((response) => {
        dispatch(supportCardDashboardSuccess(response.data));
      })
      .catch((err) => {
        dispatch(supportCardDashboardFaliure(err.message));
      });
  };
};

const getIssueStatusRequest = () => {
  return {
    type: GET_ISSUESTATUS_REQUEST,
  };
};

const getIssueStatusSuccess = (payload) => {
  return {
    type: GET_ISSUESTATUS_SUCCESS,
    payload: payload,
  };
};

const getIssueStatusFaliure = (error) => {
  return {
    type: GET_ISSUESTATUS_FAILURE,
    payload: error,
  };
};

const getIssueCommentsRequest = () => {
  return {
    type: GET_ISSUECOMMENTS_REQUEST,
  };
};

const getIssueCommentsSuccess = (payload) => {
  return {
    type: GET_ISSUECOMMENTS_SUCCESS,
    payload: payload,
  };
};

const getIssueCommentsFaliure = (error) => {
  return {
    type: GET_ISSUECOMMENTS_FAILURE,
    payload: error,
  };
};

const getIssueStatus = (id) => {
  return (dispatch) => {
    dispatch(getIssueStatusRequest());
    let url = `${licensesUrl}/jira/requests/request_by_id?issue_id=${id}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getIssueStatusSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getIssueStatusFaliure(err.message));
      });
  };
};

const getIssueComments = (id) => {
  return (dispatch) => {
    dispatch(getIssueCommentsRequest());
    let url = `${licensesUrl}/jira/requests/comments?issue_id=${id}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(getIssueCommentsSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(getIssueCommentsFaliure(err.message));
      });
  };
};

export {
  getIssues,
  createIssue,
  getRequestTypes,
  supportHomePage,
  getIssueStatus,
  getIssueComments,
};
