/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  RESTORING_REQUEST,
  RESTORING_SUCCESS,
  RESTORING_FAILURE,
  BACKUP_LIST_RESTORING_REQUEST,
  BACKUP_LIST_RESTORING_SUCCESS,
  BACKUP_LIST_RESTORING_FAILURE,
} from "./restoringTypes";
import axios from "axios";
import Config from "../../../config/index";
let mobillorUrl = Config.mobillorUrl;

const restoringRequest = () => {
  return {
    type: RESTORING_REQUEST,
  };
};

const restoringSuccess = (payload) => {
  return {
    type: RESTORING_SUCCESS,
    payload: payload,
  };
};

const restoringFaliure = (error) => {
  return {
    type: RESTORING_FAILURE,
    payload: error,
  };
};

const backupListRestoringRequest = () => {
  return {
    type: BACKUP_LIST_RESTORING_REQUEST,
  };
};

const backupListRestoringSuccess = (payload) => {
  return {
    type: BACKUP_LIST_RESTORING_SUCCESS,
    payload: payload,
  };
};

const backupListRestoringFaliure = (error) => {
  return {
    type: BACKUP_LIST_RESTORING_FAILURE,
    payload: error,
  };
};

const restoring = (backupParameters) => {
  let temp = backupParameters.backupfileslist;
  return (dispatch) => {
    dispatch(restoringRequest());
    let url = `${mobillorUrl}/backupByDate?date=${temp}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(restoringSuccess(response.data));
      })
      .catch((err) => {
        dispatch(restoringFaliure(err.message));
      });
  };
};

const getBackupList = () => {
  return (dispatch) => {
    dispatch(backupListRestoringRequest());
    let url = `${mobillorUrl}/backupFiles`;
    axios
      .get(url)
      .then((response) => {
        let list = [];
        response.data.map((item) => {
          let temp = item.split("flow");
          list.push(temp[1]);
        });
        dispatch(backupListRestoringSuccess(list));
      })
      .catch((err) => {
        dispatch(backupListRestoringFaliure(err.message));
      });
  };
};

export { restoring, getBackupList };
