/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { NODES_REQUEST, NODES_SUCCESS, NODES_FAILURE } from "./nodeTypes";

const initialState = {
  loading: false,
  nodes: [],
  error: "",
};

const nodesReducer = (state = initialState, action) => {
  switch (action.type) {
    case NODES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NODES_SUCCESS:
      return {
        loading: true,
        nodes: action.payload,
        error: "",
      };
    case NODES_FAILURE:
      return {
        loading: false,
        nodes: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default nodesReducer;
