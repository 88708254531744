/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";

function SimpleBlogCard({ icon, title, description, action, color }) {
  return (
    <Card style={{ height: "100%" }}>
      <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
        <MDBox
          variant="gradient"
          bgColor={color}
          color={color === "light" ? "dark" : "white"}
          coloredShadow={color}
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          mt={-3}
        >
          <Icon fontSize="medium" color="inherit">
            {icon}
          </Icon>
        </MDBox>
        <MDBox textAlign="right" lineHeight={1.25}>
          <MDTypography
            display="inline"
            variant="h4"
            textTransform="capitalize"
            fontWeight="bold"
          >
            {title}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox p={3}>
        <MDBox mt={-1} mb={5}>
          <MDTypography variant="body2" component="h4" color="text">
            {description}
          </MDTypography>
        </MDBox>
        {action ? (
          action.type === "external" ? (
            <MuiLink
              href={action.route}
              // target="_blank"
              // rel="noreferrer"
              style={{ position: "absolute", left: "0px", bottom: "10px" }}
            >
              <MDButton
                color={action.color ? action.color : "dark"}
                variant={action.variant ? action.variant : "text"}
              >
                {action.label}
              </MDButton>
            </MuiLink>
          ) : (
            <Link
              to={action.route}
              style={{ position: "absolute", left: "0px", bottom: "10px" }}
            >
              <MDButton
                color={action.color ? action.color : "dark"}
                variant={action.variant ? action.variant : "text"}
              >
                {action.label}
              </MDButton>
            </Link>
          )
        ) : (
          ""
        )}
      </MDBox>
    </Card>
  );
}

// Typechecking props for the SimpleBlogCard
SimpleBlogCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.any.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "dark",
      "success",
      "error",
      "info",
      "primary",
      "secondary",
      "warning",
      "light",
      "default",
    ]),
    label: PropTypes.string.isRequired,
  }),
};

export default SimpleBlogCard;
