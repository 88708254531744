/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { useEffect, useState } from "react";
import { connect } from "react-redux";

// Dashboard example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

// Dashboard components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Functions from store
import { getNodes, notificationSetup } from "../../store";

const ErrorNotificationSetup = ({ getNodes, nodes, notificationSetup }) => {
  useEffect(() => {
    getNodes();
  }, []);

  const [state, setState] = useState({
    columns: [
      { Header: "Name", accessor: "name", align: "left" },
      { Header: "Enabled", accessor: "enabled", align: "left" },
      {
        Header: "Notification setup",
        accessor: "notificationSetup",
        align: "center",
      },
    ],
    rows: [],
  });
  const { columns, rows } = state;

  useEffect(() => {
    let nodesTemp = [];
    nodes.nodes.map((node) => {
      let temp = {
        name: node.name,
        enabled: node.enabled,
        notificationSetup: (
          <Switch
            checked={node.error_notification_status}
            onChange={() => {
              notificationSetup(node);
              setTimeout(() => {
                getNodes();
              }, 100);
            }}
            color="primary"
            name="checked"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        ),
      };
      nodesTemp.push(temp);
    });
    setState({ ...state, rows: nodesTemp });
  }, [nodes]);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="error"
                borderRadius="lg"
                coloredShadow="error"
              >
                <MDTypography variant="h6" color="white">
                  Nodes
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  canSearch={true}
                  entriesPerPage={{
                    defaultValue: 5,
                    entries: [5, 10, 15, 20, 25, 30],
                  }}
                  showTotalEntries={true}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    nodes: state.nodes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNodes: () => dispatch(getNodes()),
    notificationSetup: (data) => dispatch(notificationSetup(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorNotificationSetup);
