/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import { combineReducers } from "redux";
import loginReducer from "./authentication/login/loginReducer";
import resetPasswordReducer from "./authentication/reset-password/resetPasswordReducer";
import {
  setPasswordReducer,
  setPasswordForNewUserReducer,
} from "./authentication/set-password/setPasswordReducer";
import forgotPasswordReducer from "./authentication/forgot-password/forgotPasswordReducer";
import profileReducer from "./profile/profileReducer";
import {
  usersReducer,
  addUserReducer,
  deleteUserReducer,
} from "./mobillor-users/mobillorUsersReducer";
import {
  issuesReducer,
  createIssueReducer,
  requestTypesReducer,
  supportHomePageReducer,
  issueStatusReducer,
  issueCommentsReducer,
} from "./support/supportReducer";
import errorCountReducer from "./errors/error-count/errorCountReducer";
import errorLogReducer from "./errors/error-log/errorLogReducer";
import errorNotificationSetupReducer from "./errors/error-notification-setup/errorNotificationSetupReducer";
import nodesReducer from "./nodes/nodesReducer";
import backupCardReducer from "./backup/backup-card/backupCardReducer";
import backupNowReducer from "./backup/backup-now/backupNowReducer";
import backupOtiopsReducer from "./backup/backup-options/backupOptionsReducer";
import {
  backupListReducer,
  restoringReducer,
} from "./backup/restoring/restoringReducer";
import {
  flowsReducer,
  flowsCountReducer,
  nodesByFlowReducer,
} from "./flows/flowsReducer";
import { subscriptionHomepageReducer } from "./subscription/subscriptionReducer";

const rootReducer = combineReducers({
  login: loginReducer,
  resetPassword: resetPasswordReducer,
  setPasswordReducer: setPasswordReducer,
  setPasswordForNewUserReducer: setPasswordForNewUserReducer,
  forgotPassword: forgotPasswordReducer,
  profile: profileReducer,
  mobillorUsers: usersReducer,
  addMobillorUser: addUserReducer,
  deleteMobillorUser: deleteUserReducer,
  issues: issuesReducer,
  createIssueReducer: createIssueReducer,
  requestTypes: requestTypesReducer,
  supportHomePageReducer: supportHomePageReducer,
  issueStatusReducer: issueStatusReducer,
  issueCommentsReducer: issueCommentsReducer,
  errorCount: errorCountReducer,
  errorLogReducer: errorLogReducer,
  errorNotificationSetupReducer: errorNotificationSetupReducer,
  nodes: nodesReducer,
  backupCardReducer: backupCardReducer,
  backupNowReducer: backupNowReducer,
  backupOtiopsReducer: backupOtiopsReducer,
  backupList: backupListReducer,
  restoringData: restoringReducer,
  flows: flowsReducer,
  flowsCount: flowsCountReducer,
  nodesByFlowReducer: nodesByFlowReducer,
  subscriptionHomepage: subscriptionHomepageReducer,
});

export default rootReducer;
