/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  BACKUP_CARD_REQUEST,
  BACKUP_CARD_SUCCESS,
  BACKUP_CARD_FAILURE,
} from "./backupCardTypes";

const initialState = {
  loading: false,
  backupCard: {},
  error: "",
};

const backupCardReducer = (state = initialState, action) => {
  switch (action.type) {
    case BACKUP_CARD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case BACKUP_CARD_SUCCESS:
      return {
        loading: true,
        backupCard: action.payload,
        error: "",
      };
    case BACKUP_CARD_FAILURE:
      return {
        loading: false,
        backupCard: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export default backupCardReducer;
