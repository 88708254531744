/*********************
 * Mobillor Technologies Pvt. ltd. CONFIDENTIAL
 * __________________
 *
 *  Mobillor Technologies Pvt. Ltd.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Mobillor Technologies Pvt. Ltd.
 * The intellectual and technical concepts contained
 * herein are proprietary to Mobillor Technologies Pvt. Ltd.
 * may be covered by Rebublic Of India and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Mobillor Technologies Pvt. Ltd.
 */

import {
  BACKUP_OPTIONS_REQUEST,
  BACKUP_OPTIONS_SUCCESS,
  BACKUP_OPTIONS_FAILURE,
} from "./backupOptionsTypes";
import axios from "axios";
import Config from "../../../config/index";
let mobillorUrl = Config.mobillorUrl;

const backupOptionsRequest = () => {
  return {
    type: BACKUP_OPTIONS_REQUEST,
  };
};

const backupOptionsSuccess = (payload) => {
  return {
    type: BACKUP_OPTIONS_SUCCESS,
    payload: payload,
  };
};

const backupOptionsFaliure = (error) => {
  return {
    type: BACKUP_OPTIONS_FAILURE,
    payload: error,
  };
};

const setelctBackupLocation = (payload) => {
  return (dispatch) => {
    dispatch(backupOptionsRequest());
    let url = `${mobillorUrl}/backupByLocation`;
    return axios
      .post(url, payload)
      .then((response) => {
        dispatch(backupOptionsSuccess(response.data));
        return { status: true, data: response.data };
      })
      .catch((err) => {
        dispatch(backupOptionsFaliure(err.message));
        return { status: false, data: err.message };
      });
  };
};

export { setelctBackupLocation };
